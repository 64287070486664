.links:hover{
    border-bottom: 1px solid rgb(197, 215, 255);
    transition: 0.5ms ease-in-out;
}

.highlight{
    font-size: 20px;
    font-weight: bold;
    color: #6c63ff;
}

.active{
    border-bottom: 1px solid var(--purple);
}