.showcase-div h2{
    font-size: 2.3vw;
}
.showcase-div h1{
    margin-top: 40px;
    font-size: 6vw;
}

.showcase-right-div img{
    width: 100%;
}

.typewriter{
    font-size: 2vw;
}

@media screen and (max-width:1120px) {
    .skill-graph{
        width:120px !important;
    }
}

@media screen and (max-width:634px) {
    .skill-graph{
        width:80px !important;
    }
}

.project-img{
    height: 300px;
    width: 100%;
    object-fit: contain;
}