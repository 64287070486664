*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root{
  --purple:#6C63FF;
  overflow-x: hidden;
}

body{
  position: relative;
}
body::-webkit-scrollbar{
  width: 10px;
  height: 10px;
  background-color: lightgray;
}

body::-webkit-scrollbar-thumb{
  width: 10px;
  height: 10px;
  background-color: var(--purple);
  border-radius: 4px;
  box-shadow: 3px 3px 8px #000;
}

body::-webkit-scrollbar-track{
  width: 10px;
  height: 10px;
  background-color: lightgray;
}

.center{
  margin-left: auto;
  margin-right: auto;

}

.custom-btn{
  background-color: rgb(177, 178, 252);
}

.custom-btn:hover{
  background-color: var(--purple) !important;
  color:#fff !important;
  transition: 0.5s ease-in-out;
}

.footer{
  background-color: var(--purple);
  /* position: absolute;
  bottom: 0; */
}
