.profile-img{
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 50px;
}

.about-img{
    width: 100%;
    height: 300px;
    object-fit: contain;
}